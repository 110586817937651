import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  Home as HomeIcon,
  Search as SearchIcon,
  Library,
  PlusSquare,
  Heart,
  Clock,
  ChevronLeft,
  MoreVertical,
  ChevronRight,
  Play,
  Pause,
  SkipBack,
  SkipForward,
  Repeat,
  Volume2,
  X,
  Check,
  Trash2,
  Edit,
  VolumeX,
  Menu,
  User,
  Plus,
  Camera,
  LogOut,
  Eye,
  EyeOff,
  Info,
  Settings,
} from "lucide-react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  Timestamp,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getAuth,
  updateProfile,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateEmail,
  User as FirebaseUser,
  verifyBeforeUpdateEmail,
  sendEmailVerification,
} from "firebase/auth";
import { FaPlay, FaPause, FaHeart } from "react-icons/fa";
import DOMPurify from 'dompurify';

const firebaseConfig = {
  // Votre configuration Firebase ici
  apiKey: "AIzaSyAdr1m5L6I6_ZBZxoSN3QRbjUG87xqSwnc",
  authDomain: "music-streaming-app-1296d.firebaseapp.com",
  projectId: "music-streaming-app-1296d",
  storageBucket: "music-streaming-app-1296d.appspot.com",
  messagingSenderId: "820031322331",
  appId: "1:820031322331:web:100e0aed129c642b6c74fe",
  measurementId: "G-6RZBYM3W53",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Types
interface Song {
  id?: string;
  name: string;
  artist: string;
  url: string;
  duration: number;
  thumbnailUrl?: string;
  isLiked?: boolean;
  likedBy: string[]; // Ajout de ce champ
}

interface Playlist {
  id?: string;
  name: string;
  imageUrl?: string;
  songs: string[];
  userId: string; // Ajout de ce champ
}

interface User extends Omit<FirebaseUser, 'displayName' | 'photoURL'> {
  id?: string;
  name: string;
  email: string;
  role: 'user' | 'admin';
  photoURL?: string | null;
  displayName?: string | null;
  likedSongs?: string[]; // Ajout de ce champ
}

interface CompactPlayerProps {
  currentSong: Song | null;
  isPlaying: boolean;
  togglePlay: () => void;
}

interface AuthScreenProps {
  onLogin: (email: string, password: string) => Promise<string | null>;
  onSignUp: (name: string, email: string, password: string) => Promise<string | null>;
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthScreen: React.FC<AuthScreenProps> = ({
  onLogin,
  onSignUp,
  showPassword,
  setShowPassword
}) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [showPasswordRules, setShowPasswordRules] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    let result: string | null;
    if (isSignUp) {
      if (password !== confirmPassword) {
        setError("Les mots de passe ne correspondent pas.");
        return;
      }
      result = await onSignUp(name, email, password);
    } else {
      result = await onLogin(email, password);
    }
    if (result) {
      setError(result);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-96">
        <div className="flex flex-col items-center mb-6">
          <img src="logo.png" alt="Logo" className="w-10 h-10 mb-4" />
          <h2 className="text-2xl font-bold text-white text-center">
            {isSignUp ? "Inscription" : "Connexion"}
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          {isSignUp && (
            <input
              type="text"
              placeholder="Nom d'utilisateur (max 12 caractères)"
              value={DOMPurify.sanitize(name)}
              onChange={(e) => setName(DOMPurify.sanitize(e.target.value).slice(0, 12))}
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              required
            />
          )}
          <input
            type="email"
            placeholder="Email"
            value={DOMPurify.sanitize(email)}
            onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
            className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
            required
          />
          <div className="relative mb-4">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Mot de passe"
              value={DOMPurify.sanitize(password)}
              onChange={(e) => setPassword(DOMPurify.sanitize(e.target.value))}
              className="w-full p-2 bg-gray-700 text-white rounded"
              required
            />
            {isSignUp && (
              <button
                type="button"
                onClick={() => setShowPasswordRules(!showPasswordRules)}
                className="absolute right-2 text-gray-400"
                style={{ top: "0.60rem" }}
              >
                <Info size={20} />
              </button>
            )}
          </div>
          {isSignUp && (
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Confirmer le mot de passe"
              value={DOMPurify.sanitize(confirmPassword)}
              onChange={(e) => setConfirmPassword(DOMPurify.sanitize(e.target.value))}
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              required
            />
          )}
          {isSignUp && showPasswordRules && (
            <div className="text-sm text-gray-400 mb-4">
              <p>Le mot de passe doit contenir au minimum :</p>
              <div className="flex justify-around">
                <ul className="list-disc list-inside">
                  <li>8 caractères</li>
                  <li>1 majuscule</li>
                  <li>1 minuscule</li>
                </ul>
                <ul className="list-disc list-inside">
                  <li>1 chiffre</li>
                  <li>1 caractère spécial</li>
                </ul>
              </div>
            </div>
          )}
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
          >
            {isSignUp ? "S'inscrire" : "Se connecter"}
          </button>
        </form>
        <button
          className="w-full text-center mt-4 text-blue-400 hover:text-blue-300"
          onClick={() => {
            setIsSignUp(!isSignUp);
            setError(null);
          }}
        >
          {isSignUp ? "Déjà un compte ? Se connecter" : "Première fois ici ? S'inscrire"}
        </button>
      </div>
    </div>
  );
};

const CompactPlayer: React.FC<CompactPlayerProps> = ({ currentSong, isPlaying, togglePlay }) => {
  if (!currentSong) return null;

  return (
    <div className="fixed bottom-16 left-4 right-4 bg-gradient-to-r from-green-400 to-blue-500 rounded-full p-2 flex items-center justify-between">
      <div className="flex items-center">
        <img
          src={currentSong.thumbnailUrl || "/default-album-art.jpg"}
          alt={currentSong.name}
          className="w-12 h-12 rounded-full mr-3 object-cover"
        />
        <div>
          <p className="font-semibold text-sm text-white">{currentSong.name}</p>
          <p className="text-xs text-white opacity-75">{currentSong.artist}</p>
        </div>
      </div>
      <div className="flex items-center">
        <p className="text-sm text-white mr-3">
          {Math.floor(currentSong.duration / 60)}:{String(currentSong.duration % 60).padStart(2, '0')}
        </p>
        <button
          type="button"
          onMouseDown={togglePlay}
          className="bg-white rounded-full p-2"
        >
          {isPlaying ? <Pause size={20} color="black" /> : <Play size={20} color="black" />}
        </button>
      </div>
    </div>
  );
};

// Composant principal
const App: React.FC = () => {
  const [currentSong, setCurrentSong] = useState<Song | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [songs, setSongs] = useState<Song[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isCreatePlaylistModalOpen, setIsCreatePlaylistModalOpen] = useState(false);
  const [isEditPlaylistModalOpen, setIsEditPlaylistModalOpen] = useState(false);
  const [currentEditPlaylist, setCurrentEditPlaylist] = useState<Playlist | null>(null);
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [repeatMode, setRepeatMode] = useState<"off" | "one" | "all">("off");
  const [currentSongIndex, setCurrentSongIndex] = useState<number>(0);
  const [collections, setCollections] = useState<MusicCollection[]>([]);
  const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);
  const [isAddInstrumentalModalOpen, setIsAddInstrumentalModalOpen] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const loadSongs = async () => {
    const songsSnapshot = await getDocs(collection(db, "songs"));
    const songsData = songsSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        isLiked: user ? data.likedBy?.includes(user.id) : false,
      } as Song;
    });
    setSongs(songsData);
  };

  const changeEmail = async (newEmail: string) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        await updateEmail(user, newEmail);
        await updateDoc(doc(db, "users", user.uid), { email: newEmail });
        setUser(prevUser => prevUser ? { ...prevUser, email: newEmail } : null);
      } catch (error) {
        console.error("Erreur lors du changement d'email:", error);
        throw error;
      }
    }
  };

  const handleEmailVerification = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        await user.reload();
        if (user.emailVerified) {
          // L'email a été vérifié, vous pouvez maintenant mettre à jour l'email dans Firestore
          await updateDoc(doc(db, "users", user.uid), { email: user.email });
          setUser(prevUser => prevUser ? { ...prevUser, email: user.email || "" } : null);
          setSuccess('Adresse email mise à jour avec succès.');
        } else {
          setError('Veuillez vérifier votre nouvelle adresse email avant de continuer.');
        }
      } catch (error) {
        console.error("Erreur lors de la vérification de l'email:", error);
        setError('Une erreur est survenue lors de la vérification de l\'email.');
      }
    }
  };

  useEffect(() => {
    const checkEmailVerification = async () => {
      const user = auth.currentUser;
      if (user && user.emailVerified) {
        await handleEmailVerification();
      }
    };

    checkEmailVerification();
  }, []);

  const changeName = async (newName: string) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        await updateProfile(user, { displayName: newName });
        await updateDoc(doc(db, "users", user.uid), { name: newName });
        setUser(prevUser => prevUser ? { ...prevUser, name: newName } : null);
      } catch (error) {
        console.error("Erreur lors du changement de nom:", error);
        throw error;
      }
    }
  };

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password: string): boolean => {
    // Au moins 8 caractères
    const minLength = password.length >= 8;

    // Au moins une majuscule
    const hasUpperCase = /[A-Z]/.test(password);

    // Au moins une minuscule
    const hasLowerCase = /[a-z]/.test(password);

    // Au moins un chiffre
    const hasNumber = /\d/.test(password);

    // Au moins un caractère spécial
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>\-]/.test(password);

    return minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };

  const togglePlay = useCallback(async () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  }, []);

  const handleSetCurrentSong = useCallback(async (song: Song) => {
    try {
      if (currentSong?.id !== song.id) {
        setCurrentSong(song);
        setIsPlaying(true);
      } else {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
      }
      setCurrentSongIndex(songs.findIndex((s) => s.id === song.id));
    } catch (error) {
      console.error("Erreur lors du changement de chanson:", error);
    }
  }, [currentSong, songs]);

  const togglePlayRef = useRef(togglePlay);
  const handleSetCurrentSongRef = useRef(handleSetCurrentSong);

  useEffect(() => {
    togglePlayRef.current = togglePlay;
    handleSetCurrentSongRef.current = handleSetCurrentSong;
  }, [togglePlay, handleSetCurrentSong]);

  const handlePlayClick = async (e: React.MouseEvent<HTMLButtonElement>, song: Song) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (currentSong?.id === song.id) {
      await togglePlayRef.current();
    } else {
      await handleSetCurrentSongRef.current(song);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser({
          ...user,  // Ceci inclut toutes les propriétés de FirebaseUser
          id: user.uid,
          name: user.displayName || "",
          email: user.email || "",
          role: "user",
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async (email: string, password: string): Promise<string | null> => {
    try {
      await login(email, password);
      return null;
    } catch (error) {
      console.error("Erreur de connexion:", error);
      return "Erreur lors de la connexion. Veuillez réessayer.";
    }
  };

  const handleSignUp = async (name: string, email: string, password: string): Promise<string | null> => {
    try {
      const result = await signUp(name, email, password);
      if (result === null) {
        setIsAuthModalOpen(false);  // Fermer la modal seulement si l'inscription réussit
      }
      return result;
    } catch (error) {
      console.error("Erreur d'inscription:", error);
      return "Erreur lors de l'inscription. Veuillez réessayer.";
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const credential = EmailAuthProvider.credential(
        user.email!,
        oldPassword
      );

      try {
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
      } catch (error) {
        console.error("Erreur lors du changement de mot de passe:", error);
        throw error;
      }
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio || !currentSong) return;

    // Mettre à jour la source seulement si la chanson a changé
    if (audio.src !== currentSong.url) {
      audio.src = currentSong.url;
    }

    if (isPlaying) {
      audio.play().catch(error => console.error("Erreur lors de la lecture:", error));
    } else {
      audio.pause();
    }
  }, [currentSong, isPlaying, audioRef]);

  useEffect(() => {
    // Charger les playlists et les chansons depuis Firestore
    const fetchData = async () => {
      const playlistsSnapshot = await getDocs(collection(db, "playlists"));
      const playlistsData = playlistsSnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() }) as Playlist,
      );
      setPlaylists(playlistsData);

      const songsSnapshot = await getDocs(collection(db, "songs"));
      const songsData = songsSnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() }) as Song,
      );
      setSongs(songsData);
    };

    fetchData();
  }, []);

  const updatePlaylistImage = async (playlistId: string, newImageUrl: string) => {
    try {
      const updatedPlaylists = playlists.map(playlist =>
        playlist.id === playlistId
          ? { ...playlist, imageUrl: newImageUrl }
          : playlist
      );
      setPlaylists(updatedPlaylists);

      // Mise à jour dans Firebase
      const playlistRef = doc(db, "playlists", playlistId);
      await updateDoc(playlistRef, { imageUrl: newImageUrl });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image de la playlist:", error);
    }
  };

  const updatePlaylistName = async (playlistId: string, newName: string) => {
    try {
      const updatedPlaylists = playlists.map(playlist =>
        playlist.id === playlistId
          ? { ...playlist, name: newName }
          : playlist
      );
      setPlaylists(updatedPlaylists);

      // Mise à jour dans Firebase
      const playlistRef = doc(db, "playlists", playlistId);
      await updateDoc(playlistRef, { name: newName });
    } catch (error) {
      console.error("Erreur lors de la mise à jour du nom de la playlist:", error);
    }
  };

  useEffect(() => { }, [newPlaylistName]);

  const openEditPlaylistModal = (playlist: Playlist) => {
    setCurrentEditPlaylist(playlist);
    setNewPlaylistName(playlist.name);
    setIsEditPlaylistModalOpen(true);
  };

  const addInstrumental = (name: string, artist: string, audioFile: File, imageFile: File | null, duration: number) => {
    handleAddInstrumental(name, artist, audioFile, imageFile, duration);
  };

  const [createPlaylistName, setCreatePlaylistName] = useState("");
  const [editPlaylistName, setEditPlaylistName] = useState("");

  const handleEditSong = async (editedSong: Song) => {
    const songRef = doc(db, "songs", editedSong.id!);
    await updateDoc(songRef, {
      name: editedSong.name,
      artist: editedSong.artist,
    });
    setSongs(songs.map((s) => (s.id === editedSong.id ? editedSong : s)));
  };

  const handleDeleteSong = async (songId: string) => {
    // Logique pour supprimer la chanson de Firestore
    await deleteDoc(doc(db, "songs", songId));
    // Supprimer le fichier audio et la thumbnail du Storage
    // Mettre à jour l'état local
    setSongs(songs.filter((song) => song.id !== songId));
  };

  // Utilisez createPlaylistName et setCreatePlaylistName pour la modal de création
  // Utilisez editPlaylistName et setEditPlaylistName pour la modal d'édition

  interface AddSongModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSongAdded: () => void;
  }

  const AddSongModal: React.FC<AddSongModalProps> = ({ isOpen, onClose, onSongAdded }) => {
    const [audioFile, setAudioFile] = useState<File | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [artist, setArtist] = useState("");
    const [title, setTitle] = useState("");
    const [duration, setDuration] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
    const ALLOWED_AUDIO_TYPES = ['audio/mpeg', 'audio/wav', 'audio/ogg'];
    const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

    const validateFile = (file: File, allowedTypes: string[], maxSize: number): string | null => {
      if (!allowedTypes.includes(file.type)) {
        return "Type de fichier non autorisé.";
      }
      if (file.size > maxSize) {
        return `La taille du fichier ne doit pas dépasser ${maxSize / 1024 / 1024}MB.`;
      }
      return null;
    };

    const handleAudioFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const error = validateFile(file, ALLOWED_AUDIO_TYPES, MAX_FILE_SIZE);
        if (error) {
          setError(error);
          return;
        }
        setAudioFile(file);
        setError(null);

        const audio = new Audio(URL.createObjectURL(file));
        audio.onloadedmetadata = () => {
          setDuration(Math.round(audio.duration));
          const fileName = file.name.replace(/\.[^/.]+$/, "");
          const parts = fileName.split('-');
          if (parts.length >= 2) {
            setArtist(DOMPurify.sanitize(parts[0].trim()));
            setTitle(DOMPurify.sanitize(parts.slice(1).join('-').trim()));
          } else {
            setTitle(DOMPurify.sanitize(fileName));
          }
        };
      }
    };

    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const error = validateFile(file, ALLOWED_IMAGE_TYPES, MAX_FILE_SIZE);
        if (error) {
          setError(error);
          return;
        }
        setImageFile(file);
        setError(null);
      }
    };

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!audioFile) {
        setError("Veuillez sélectionner un fichier audio.");
        return;
      }

      setIsLoading(true);
      setError(null);

      const storage = getStorage();
      const audioRef = ref(storage, `songs/${audioFile.name}`);
      let imageUrl = "";

      try {
        console.log("Uploading audio file...");
        await uploadBytes(audioRef, audioFile);
        const audioUrl = await getDownloadURL(audioRef);
        console.log("Audio file uploaded successfully");

        if (imageFile) {
          console.log("Uploading image file...");
          const imageRef = ref(storage, `thumbnails/${imageFile.name}`);
          await uploadBytes(imageRef, imageFile);
          imageUrl = await getDownloadURL(imageRef);
          console.log("Image file uploaded successfully");
        }

        const songData = {
          title,
          artist,
          duration,
          url: audioUrl,
          thumbnailUrl: imageUrl,
          isLiked: false,
        };

        console.log("Adding song to Firestore...");
        await addDoc(collection(db, "songs"), songData);
        console.log("Song added successfully");

        onSongAdded();
        onClose();
      } catch (error) {
        console.error("Error adding song: ", error);
        setError(
          "Une erreur s'est produite lors de l'ajout de la chanson. Veuillez réessayer.",
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (!isOpen) return null;

    if (loading) {
      return <div>Chargement...</div>;
    }

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-gray-800 p-6 rounded-lg relative w-96">
          <button type="button" onMouseDown={onClose} className="absolute top-2 right-2 text-gray-400 hover:text-white">
            <X size={24} />
          </button>
          <h2 className="text-xl font-bold mb-4">Ajouter une Instrumentale</h2>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Fichier audio (MP3, WAV, OGG, max 10MB)
              </label>
              <input
                type="file"
                accept=".mp3,.wav,.ogg"
                onChange={handleAudioFileChange}
                className="w-full text-sm text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-700 file:text-white hover:file:bg-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Image de couverture (JPG, PNG, GIF, max 10MB)
              </label>
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.gif"
                onChange={handleImageFileChange}
                className="w-full text-sm text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-700 file:text-white hover:file:bg-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Artiste
              </label>
              <input
                type="text"
                value={DOMPurify.sanitize(artist)}
                onChange={(e) => setArtist(DOMPurify.sanitize(e.target.value))}
                className="w-full p-2 bg-gray-700 text-white rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Titre
              </label>
              <input
                type="text"
                value={DOMPurify.sanitize(title)}
                onChange={(e) => setTitle(DOMPurify.sanitize(e.target.value))}
                className="w-full p-2 bg-gray-700 text-white rounded"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 disabled:bg-gray-400"
              disabled={isLoading}
            >
              {isLoading ? "Ajout en cours..." : "Ajouter la musique"}
            </button>
          </form>
        </div>
      </div>
    );
  };

  const handleAddInstrumental = async (name: string, artist: string, audioFile: File, imageFile: File | null, duration: number) => {
    try {
      const storage = getStorage();
      const audioRef = ref(storage, `instrumentals/${audioFile.name}`);
      await uploadBytes(audioRef, audioFile);
      const url = await getDownloadURL(audioRef);

      let thumbnailUrl = "";
      if (imageFile) {
        const imageRef = ref(storage, `thumbnails/${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        thumbnailUrl = await getDownloadURL(imageRef);
      }

      const newInstrumental: Omit<Song, 'id'> = {
        name,
        artist,
        url,
        duration,
        thumbnailUrl,
        isLiked: false,
        likedBy: []  // Ajout de cette ligne
      };
      const docRef = await addDoc(collection(db, 'songs'), newInstrumental);
      setSongs(prevSongs => [...prevSongs, { id: docRef.id, ...newInstrumental }]);
    } catch (error) {
      console.error("Error adding instrumental: ", error);
      alert("Une erreur s'est produite lors de l'ajout de l'instrumental.");
    }
  };

  const handleAddCollection = async (newCollection: Omit<MusicCollection, 'id'>) => {
    try {
      const docRef = await addDoc(collection(db, 'collections'), newCollection);
      setCollections([...collections, { id: docRef.id, ...newCollection }]);
    } catch (error) {
      console.error("Error adding collection: ", error);
      alert("Une erreur s'est produite lors de l'ajout de la collection.");
    }
  };

  interface EditSongModalProps {
    isOpen: boolean;
    onClose: () => void;
    songs: Song[];
    onEditSong: (song: Song) => void;
  }

  interface MobilePlayerBarProps {
    currentSong: Song | null;
    isPlaying: boolean;
    togglePlay: () => void;
    audioRef: React.RefObject<HTMLAudioElement>;
  }

  const MobilePlayerBar: React.FC<MobilePlayerBarProps> = ({
    currentSong,
    isPlaying,
    togglePlay,
    audioRef
  }) => {
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
      const audio = audioRef.current;
      if (!audio) return;

      const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime);
      };

      const handleSongEnd = () => {
        playNext();
      };

      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('ended', handleSongEnd);

      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('ended', handleSongEnd);
      };
    }, [audioRef, playNext]);

    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
      <div className="fixed bottom-0 left-0 right-0 bg-black text-white">
        {currentSong && (
          <div className="px-4 py-2 flex items-center justify-between bg-gradient-to-r from-green-400 to-blue-500 rounded-t-xl">
            <div className="flex items-center">
              <img
                src={currentSong.thumbnailUrl || "/default-album-art.jpg"}
                alt={currentSong.name}
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <p className="font-semibold text-sm">{currentSong.name}</p>
                <p className="text-xs opacity-75">{currentSong.artist}</p>
              </div>
            </div>
            <div className="flex items-center">
              <p className="text-sm mr-3">
                {formatTime(currentTime)} / {formatTime(currentSong.duration)}
              </p>
              <button
                type="button"
                onMouseDown={(e) => {
                  e.preventDefault();
                  togglePlay();
                }}
                className="bg-white rounded-full p-2"
              >
                {isPlaying ? <FaPause size={15} color="black" /> : <FaPlay size={15} color="black" />}
              </button>
            </div>
          </div>
        )}
        <nav className="flex justify-around items-center py-3 bg-gray-900">
          <Link to="/" className="text-white">
            <HomeIcon size={24} />
          </Link>
          <Link to="/collection" className="text-white">
            <Library size={24} />
          </Link>
          <Link to="/liked" className="text-white">
            <Heart size={24} />
          </Link>
          <Link to="/profile" className="text-white">
            <User size={24} />
          </Link>
        </nav>
      </div>
    );
  };

  interface CollectionProps {
    user: User | null;
    setCurrentSong: (song: Song) => void;
    currentSong: Song | null;
    isPlaying: boolean;
    togglePlay: () => void;
    toggleLikeSong: (songId: string) => void;
    playlists: Playlist[];
    instrumentals: Song[];
    onAddPlaylist: (name: string) => Promise<Playlist>;
    onAddInstrumental: (name: string, artist: string, audioFile: File, imageFile: File | null, duration: number) => void;
    addSongToPlaylist: (playlistId: string, songId: string) => Promise<void>;
  }

  interface MusicCollection {
    id?: string;
    name: string;
    releaseDate?: Timestamp;
    imageUrl: string;
    songs?: Song[];
  }

  interface Instrumental {
    id?: string;
    name: string;
    artist: string;
    url: string;
    duration: number;
    thumbnailUrl?: string;
    isLiked?: boolean;
  }

  const Collection: React.FC<CollectionProps> = ({
    setCurrentSong,
    currentSong,
    isPlaying,
    togglePlay,
    toggleLikeSong,
    playlists,
    instrumentals,
    onAddPlaylist,
    onAddInstrumental,
    addSongToPlaylist,
    user,
  }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);
    const [isAddInstrumentalModalOpen, setIsAddInstrumentalModalOpen] = useState(false);
    const [selectedInstrumental, setSelectedInstrumental] = useState<Song | null>(null);
    const [isPlaylistListOpen, setIsPlaylistListOpen] = useState(false);
    const navigate = useNavigate();
    const searchRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [isAddToPlaylistModalOpen, setIsAddToPlaylistModalOpen] = useState(false);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
          setIsSearchOpen(false);
        }
      };

      if (isSearchOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isSearchOpen]);

    useEffect(() => {
      if (isSearchOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [isSearchOpen]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    };

    const toggleSearch = () => {
      setIsSearchOpen(!isSearchOpen);
      if (!isSearchOpen && searchInputRef.current) {
        setTimeout(() => searchInputRef.current?.focus(), 0);
      }
    };

    const filteredInstrumentals = instrumentals.filter(song =>
      song.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      song.artist.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredPlaylists = playlists.filter(playlist =>
      playlist.userId === user?.id && playlist.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleAddToPlaylist = (instrumental: Song) => {
      setSelectedInstrumental(instrumental);
      setIsAddToPlaylistModalOpen(true);
    };

    const handlePlaylistSelect = async (playlistId: string) => {
      if (selectedInstrumental && selectedInstrumental.id) {
        await addSongToPlaylist(playlistId, selectedInstrumental.id);
        setIsAddToPlaylistModalOpen(false);
        setSelectedInstrumental(null);
      }
    };

    const handleCreateNewPlaylist = async (name: string) => {
      const newPlaylist = await onAddPlaylist(name);
      if (selectedInstrumental && newPlaylist.id) {
        await addSongToPlaylist(newPlaylist.id, selectedInstrumental.id!);
      }
      setIsPlaylistListOpen(false);
      setSelectedInstrumental(null);
    };

    const handleLike = (song: Song) => {
      if (song.id) {
        toggleLikeSong(song.id);
      }
    };

    return (
      <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 to-black text-white p-4">
        {currentSong && (
          <div
            className="mb-6 p-4 rounded-lg relative overflow-hidden"
            style={{
              backgroundImage: `url(${currentSong.thumbnailUrl || "/api/placeholder/400/400"})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '200px',
            }}
          >
            <div className="absolute inset-0 bg-black opacity-50 backdrop-blur-md"></div>
            <div className="relative z-10 flex flex-col justify-center h-full">
              <div className="flex-1 flex flex-col justify-center">
                <h2 className="text-2xl font-bold">{currentSong.name}</h2>
                <p className="text-gray-300">{currentSong.artist}</p>
              </div>
              <div className="absolute top-2 right-2">
                <button
                  type="button"
                  onMouseDown={() => currentSong.id && handleLike(currentSong)}
                  className={`text-${user?.likedSongs?.includes(currentSong.id!) ? 'red' : 'white'}-500 hover:text-red-500`}
                >
                  <Heart
                    size={24}
                    fill={currentSong.isLiked ? "currentColor" : "none"}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <button type="button" onMouseDown={() => navigate('/')} className="mr-4">
              <ChevronLeft size={24} />
            </button>
            <h1 className="text-3xl font-bold">Collection</h1>
          </div>
          <div ref={searchRef} className="relative">
            <SearchIcon
              className="text-white cursor-pointer"
              size={24}
              onMouseDown={toggleSearch}
            />
          </div>
        </div>

        {isSearchOpen && (
          <div className="w-full mt-2 mb-6">
            <input
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full pl-10 pr-4 py-2 bg-gray-800 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        )}

        {/* Playlists */}
        <section className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Mes Playlists</h2>
            <button
              type="button"
              onClick={() => setIsAddPlaylistModalOpen(true)}
              className="text-white rounded-full p-2"
            >
              <Plus size={24} />
            </button>
          </div>
          <div className="flex space-x-4 overflow-x-auto scrollbar-hide">
            {filteredPlaylists.map((playlist) => (
              <div
                key={playlist.id}
                className="flex-shrink-0 w-40 cursor-pointer"
                onMouseDown={() => navigate(`/playlist/${playlist.id}`)}
              >
                <img
                  src={playlist.imageUrl || "/placeholder_playlist.PNG"}
                  alt={playlist.name}
                  className="w-40 h-40 object-cover rounded-lg shadow-lg"
                />
                <p className="mt-2 font-semibold text-sm truncate">{playlist.name}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Instrumentales */}
        <section className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Les instrumentales</h2>
            <button
              type="button"
              onMouseDown={() => setIsAddInstrumentalModalOpen(true)}
              className="text-white rounded-full p-2"
            >
              <Plus size={24} />
            </button>
          </div>
          <div className="flex space-x-4 overflow-x-auto scrollbar-hide">
            {filteredInstrumentals.map((song) => (
              <div key={song.id} className="flex-shrink-0 w-40">
                <div className="relative">
                  <img
                    src={song.thumbnailUrl || "/api/placeholder/160/160"}
                    alt={song.name}
                    className={`w-40 h-40 object-cover rounded-lg album-cover ${currentSong?.id === song.id && isPlaying ? 'spinning' : ''}`}
                  />
                  <button
                    type="button"
                    onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => handlePlayClick(e, song)}
                    className="absolute bottom-2 right-2 bg-green-500 text-white rounded-full p-2 hover:bg-green-600"
                  >
                    {currentSong?.id === song.id && isPlaying ? (
                      <FaPause size={15} />
                    ) : (
                      <FaPlay size={15} />
                    )}
                  </button>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <div className="flex space-x-2">
                    <button
                      type="button"
                      onMouseDown={() => handleAddToPlaylist(song)}
                      className="text-white hover:text-green-500"
                    >
                      <Plus size={20} />
                    </button>
                    <button
                      type="button"
                      onMouseDown={() => song.id ? toggleLikeSong(song.id) : undefined}
                      className={`text-${user?.likedSongs?.includes(song.id!) ? 'red' : 'white'}-500 hover:text-red-500`}
                    >
                      <Heart size={20} fill={song.id && user?.likedSongs?.includes(song.id) ? "currentColor" : "none"} />
                    </button>
                  </div>
                </div>
                <p className="mt-1 font-semibold text-sm truncate">{song.name}</p>
                <p className="text-xs text-gray-400 truncate">{song.artist}</p>
              </div>
            ))}
          </div>

          {isAddToPlaylistModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-gray-800 p-6 rounded-lg w-80">
                <h3 className="text-xl font-bold mb-4">Ajouter à une playlist</h3>
                <button
                  type="button"
                  onMouseDown={() => {/* Logique pour créer une nouvelle playlist */ }}
                  className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded mb-2"
                >
                  Créer une nouvelle playlist
                </button>
                {playlists.filter(playlist => playlist.userId === user?.id).map((playlist) => (
                  <button
                    type="button"
                    key={playlist.id}
                    onMouseDown={() => handlePlaylistSelect(playlist.id!)}
                    className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded"
                  >
                    {playlist.name}
                  </button>
                ))}
                <button
                  type="button"
                  onMouseDown={() => setIsAddToPlaylistModalOpen(false)}
                  className="mt-4 w-full bg-red-500 text-white py-2 px-4 rounded"
                >
                  Annuler
                </button>
              </div>
            </div>
          )}
        </section>

        {/* Playlist List Modal */}
        {isPlaylistListOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-gray-800 p-6 rounded-lg w-80 relative">
              <button
                type="button"
                onMouseDown={() => setIsPlaylistListOpen(false)}
                className="absolute top-2 right-2 text-gray-400 hover:text-white"
              >
                <X size={24} />
              </button>
              <h3 className="text-xl font-bold mb-4">Ajouter à une playlist</h3>
              <button
                type="button"
                onMouseDown={() => setIsAddPlaylistModalOpen(true)}
                className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded"
              >
                Créer une nouvelle playlist
              </button>
              {playlists.map((playlist) => (
                <button
                  type="button"
                  key={playlist.id}
                  onMouseDown={() => handlePlaylistSelect(playlist.id!)}
                  className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded"
                >
                  {playlist.name}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Modals */}
        <AddPlaylistModal
          isOpen={isAddPlaylistModalOpen}
          onClose={() => setIsAddPlaylistModalOpen(false)}
          onAddPlaylist={handleCreateNewPlaylist}
        />
        <AddInstrumentalModal
          isOpen={isAddInstrumentalModalOpen}
          onClose={() => setIsAddInstrumentalModalOpen(false)}
          onAddInstrumental={onAddInstrumental}
        />
      </div>
    );
  };

  interface AddPlaylistModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAddPlaylist: (name: string) => void;
  }

  const AddPlaylistModal: React.FC<AddPlaylistModalProps> = ({ isOpen, onClose, onAddPlaylist }) => {
    const [name, setName] = useState('');
    const modalRef = useRef<HTMLDivElement>(null);
    const [addStatus, setAddStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
          onClose();
        }
      };

      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen, onClose]);

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (name.trim()) {
        try {
          setAddStatus('loading');
          await onAddPlaylist(name);
          setAddStatus('success');
          setTimeout(() => {
            setAddStatus('idle');
            setName('');
            onClose();
          }, 3000);
        } catch (error) {
          console.error("Erreur lors de la création de la playlist:", error);
          setAddStatus('error');
          setTimeout(() => setAddStatus('idle'), 3000);
        }
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div ref={modalRef} className="bg-gray-800 p-6 rounded-lg relative w-96">
          <button type="button" onMouseDown={onClose} className="absolute top-2 right-2 text-gray-400 hover:text-white">
            <X size={24} />
          </button>
          <h2 className="text-xl font-bold mb-4">Ajouter une playlist</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={DOMPurify.sanitize(name)}
              onChange={(e) => setName(DOMPurify.sanitize(e.target.value).slice(0, 14))}
              placeholder="Nom de la playlist"
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              required
            />
            <button
              type="submit"
              className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
              disabled={addStatus === 'loading'}
            >
              {addStatus === 'loading' ? "Création en cours..." : "Ajouter la playlist"}
            </button>
            {addStatus === 'success' && (
              <p className="text-green-500 mt-2">Playlist créée avec succès!</p>
            )}
            {addStatus === 'error' && (
              <p className="text-red-500 mt-2">Erreur lors de la création de la playlist.</p>
            )}
          </form>
        </div>
      </div>
    );
  };

  interface AddInstrumentalModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAddInstrumental: (name: string, artist: string, audioFile: File, imageFile: File | null, duration: number) => void;
  }

  const AddInstrumentalModal: React.FC<AddInstrumentalModalProps> = ({ isOpen, onClose, onAddInstrumental }) => {
    const [name, setName] = useState('');
    const [artist, setArtist] = useState('');
    const [audioFile, setAudioFile] = useState<File | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [duration, setDuration] = useState(0);
    const [addStatus, setAddStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

    const handleAudioFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        setAudioFile(file);

        // Extraire le nom et l'artiste du nom du fichier
        const fileName = file.name.replace(/\.[^/.]+$/, ""); // Enlève l'extension
        const parts = fileName.split('-');
        if (parts.length >= 2) {
          setArtist(parts[0].trim());
          setName(parts.slice(1).join('-').trim());
        } else {
          setName(fileName);
        }

        // Obtenir la durée du fichier audio
        const audio = new Audio(URL.createObjectURL(file));
        audio.onloadedmetadata = () => {
          setDuration(Math.round(audio.duration));
        };
      }
    };

    const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        setImageFile(e.target.files[0]);
      }
    };

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setError(null);
      if (name.trim() && artist.trim() && audioFile && imageFile) {
        try {
          setAddStatus('loading');
          await onAddInstrumental(name, artist, audioFile, imageFile, duration);
          setAddStatus('success');
          setTimeout(() => {
            setAddStatus('idle');
            onClose();
          }, 3000);
        } catch (error) {
          console.error("Erreur lors de l'ajout de l'instrumental:", error);
          setAddStatus('error');
          setError("Une erreur s'est produite lors de l'ajout de l'instrumental.");
          setTimeout(() => setAddStatus('idle'), 3000);
        }
      } else {
        setError("Veuillez remplir tous les champs et sélectionner une image.");
      }
    };

    if (!isOpen) return null;

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-gray-800 p-6 rounded-lg relative w-96">
          <button type="button" onMouseDown={onClose} className="absolute top-2 right-2 text-gray-400 hover:text-white">
            <X size={24} />
          </button>
          <h2 className="text-xl font-bold mb-4">Ajouter un instrumental</h2>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Fichier audio
              </label>
              <input
                type="file"
                accept="audio/*"
                onChange={handleAudioFileChange}
                className="w-full text-sm text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-700 file:text-white hover:file:bg-gray-600"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Nom de l'instrumental
              </label>
              <input
                type="text"
                value={DOMPurify.sanitize(name)}
                onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
                className="w-full p-2 bg-gray-700 text-white rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Artiste
              </label>
              <input
                type="text"
                value={DOMPurify.sanitize(artist)}
                onChange={(e) => setArtist(DOMPurify.sanitize(e.target.value))}
                className="w-full p-2 bg-gray-700 text-white rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Image de couverture
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageFileChange}
                className="w-full text-sm text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-700 file:text-white hover:file:bg-gray-600"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
              disabled={addStatus === 'loading'}
            >
              {addStatus === 'loading' ? "Ajout en cours..." : "Ajouter l'instrumental"}
            </button>
            {addStatus === 'success' && (
              <p className="text-green-500 mt-2">Instrumental ajouté avec succès!</p>
            )}
            {addStatus === 'error' && (
              <p className="text-red-500 mt-2">Erreur lors de l'ajout de l'instrumental.</p>
            )}
          </form>
        </div>
      </div>
    );
  };

  interface AddCollectionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAddCollection: (collection: Omit<MusicCollection, 'id'>) => void;
  }

  const AddCollectionModal: React.FC<AddCollectionModalProps> = ({ isOpen, onClose, onAddCollection }) => {
    const [name, setName] = useState('');
    const [releaseDate, setReleaseDate] = useState('');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
          onClose();
        }
      };

      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen, onClose]);

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!name || !releaseDate || !imageFile) {
        alert('Veuillez remplir tous les champs');
        return;
      }

      const storage = getStorage();
      const imageRef = ref(storage, `collection-images/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      const imageUrl = await getDownloadURL(imageRef);

      const newCollection: Omit<MusicCollection, 'id'> = {
        name,
        releaseDate: Timestamp.fromDate(new Date(releaseDate)),
        imageUrl,
        songs: []
      };

      onAddCollection(newCollection);
      onClose();
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div ref={modalRef} className="bg-gray-800 p-6 rounded-lg relative w-96">
          <button
            type="button"
            onMouseDown={onClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
          >
            <X size={24} />
          </button>
          <h2 className="text-xl font-bold mb-4">Ajouter une collection</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Nom de la collection"
              value={DOMPurify.sanitize(name)}
              onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              required
            />
            <input
              type="date"
              value={DOMPurify.sanitize(releaseDate)}
              onChange={(e) => setReleaseDate(DOMPurify.sanitize(e.target.value))}
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              required
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setImageFile(e.target.files ? e.target.files[0] : null)}
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              required
            />
            <button
              type="submit"
              className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
            >
              Ajouter la collection
            </button>
          </form>
        </div>
      </div>
    );
  };

  interface MobileLayoutProps {
    children: React.ReactNode;
    currentSong: Song | null;
    isPlaying: boolean;
    togglePlay: () => void;
    audioRef: React.RefObject<HTMLAudioElement>;
  }

  const MobileLayout: React.FC<MobileLayoutProps> = ({
    children,
    currentSong,
    isPlaying,
    togglePlay,
    audioRef
  }) => {
    return (
      <div className="flex flex-col min-h-screen bg-black text-white">
        <main className="flex-grow overflow-y-auto pb-32">{children}</main>
        <MobilePlayerBar
          currentSong={currentSong}
          isPlaying={isPlaying}
          togglePlay={togglePlay}
          audioRef={audioRef}
        />
      </div>
    );
  };

  const EditSongModal: React.FC<EditSongModalProps> = ({
    isOpen,
    onClose,
    songs,
    onEditSong,
  }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedSong, setSelectedSong] = useState<Song | null>(null);
    const [editedName, seteditedName] = useState("");
    const [editedArtist, setEditedArtist] = useState("");

    const filteredSongs = songs && Array.isArray(songs)
      ? songs.filter(
        (song) =>
          song && song.name && song.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          song && song.artist && song.artist.toLowerCase().includes(searchTerm.toLowerCase())
      )
      : [];

    const handleSongSelect = (song: Song) => {
      setSelectedSong(song);
      seteditedName(song.name);
      setEditedArtist(song.artist);
    };

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if (selectedSong) {
        onEditSong({
          ...selectedSong,
          name: editedName,
          artist: editedArtist,
        });
        onClose();
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-gray-800 p-6 rounded-lg relative w-96">
          <button
            type="button"
            onMouseDown={onClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
          >
            <X size={24} />
          </button>
          <h2 className="text-xl font-bold mb-4">Modifier une musique</h2>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Rechercher une musique..."
              value={DOMPurify.sanitize(searchTerm)}
              onChange={(e) => setSearchTerm(DOMPurify.sanitize(e.target.value))}
              className="w-full p-2 bg-gray-700 text-white rounded"
            />
          </div>
          <div className="max-h-40 overflow-y-auto mb-4">
            {filteredSongs.map((song) => (
              <div
                key={song.id}
                className="p-2 hover:bg-gray-700 text-white cursor-pointer"
                onMouseDown={() => handleSongSelect(song)}
              >
                {song.name} - {song.artist}
              </div>
            ))}
          </div>
          {selectedSong && (
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={DOMPurify.sanitize(editedName)}
                onChange={(e) => seteditedName(DOMPurify.sanitize(e.target.value))}
                className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
                placeholder="Titre"
              />
              <input
                type="text"
                value={DOMPurify.sanitize(editedArtist)}
                onChange={(e) => setEditedArtist(DOMPurify.sanitize(e.target.value))}
                className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
                placeholder="Artiste"
              />
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded"
              >
                Mettre à jour
              </button>
            </form>
          )}
        </div>
      </div>
    );
  };

  interface DeleteSongModalProps {
    isOpen: boolean;
    onClose: () => void;
    songs: Song[];
    onDeleteSong: (songId: string) => void;
  }

  const DeleteSongModal: React.FC<DeleteSongModalProps> = ({
    isOpen,
    onClose,
    songs,
    onDeleteSong,
  }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedSong, setSelectedSong] = useState<Song | null>(null);

    const filteredSongs = songs.filter((song) =>
      (song.name?.toLowerCase().includes(searchTerm.toLowerCase()) || false) ||
      (song.artist?.toLowerCase().includes(searchTerm.toLowerCase()) || false)
    );

    const handleDelete = () => {
      if (
        selectedSong &&
        window.confirm(
          `Êtes-vous sûr de vouloir supprimer "${selectedSong.name}" ?`,
        )
      ) {
        onDeleteSong(selectedSong.id!);
        onClose();
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-gray-800 p-6 rounded-lg relative w-96">
          <button
            type="button"
            onMouseDown={onClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
          >
            <X size={24} />
          </button>
          <h2 className="text-xl font-bold mb-4">Supprimer une musique</h2>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Rechercher une musique..."
              value={DOMPurify.sanitize(searchTerm)}
              onChange={(e) => setSearchTerm(DOMPurify.sanitize(e.target.value))}
              className="w-full p-2 bg-gray-700 text-white rounded"
            />
          </div>
          <div className="max-h-40 overflow-y-auto mb-4">
            {filteredSongs.map((song) => (
              <div
                key={song.id}
                className="p-2 hover:bg-gray-700 text-white cursor-pointer"
                onMouseDown={() => setSelectedSong(song)}
              >
                {song.name} - {song.artist}
              </div>
            ))}
          </div>
          {selectedSong && (
            <button
              type="button"
              onMouseDown={handleDelete}
              className="w-full bg-red-500 text-white py-2 px-4 rounded"
            >
              Supprimer {selectedSong.name}
            </button>
          )}
        </div>
      </div>
    );
  };

  const refreshSongs = async () => {
    console.log("Refreshing songs...");
    try {
      const songsSnapshot = await getDocs(collection(db, "songs"));
      const songsData = songsSnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() }) as Song,
      );
      setSongs(songsData);
      console.log("Songs refreshed successfully");
    } catch (error) {
      console.error("Error refreshing songs:", error);
    }
  };

  const toggleLikeSong = async (songId: string) => {
    if (!user || !user.id) {
      console.error("User or user ID is undefined");
      return;
    }

    const songRef = doc(db, "songs", songId);
    const userRef = doc(db, "users", user.id);

    try {
      const songDoc = await getDoc(songRef);
      const userDoc = await getDoc(userRef);

      if (songDoc.exists() && userDoc.exists()) {
        const song = songDoc.data() as Song;
        const userData = userDoc.data() as User;
        const likedSongs = userData.likedSongs || [];

        let updatedLikedSongs: string[];
        if (likedSongs.includes(songId)) {
          updatedLikedSongs = likedSongs.filter(id => id !== songId);
        } else {
          updatedLikedSongs = [...likedSongs, songId];
        }

        await updateDoc(userRef, { likedSongs: updatedLikedSongs });

        // Mettre à jour l'état local
        setUser(prevUser => prevUser ? { ...prevUser, likedSongs: updatedLikedSongs } : null);
        setSongs(prevSongs => prevSongs.map(s =>
          s.id === songId ? { ...s, isLiked: updatedLikedSongs.includes(songId) } : s
        ));
      }
    } catch (error) {
      console.error("Erreur lors du toggle like:", error);
    }
  };

  const playNext = () => {
    if (currentSongIndex < songs.length - 1) {
      setCurrentSongIndex(currentSongIndex + 1);
      setCurrentSong(songs[currentSongIndex + 1]);
    } else {
      // Revenir au début de la liste
      setCurrentSongIndex(0);
      setCurrentSong(songs[0]);
    }
    setIsPlaying(true);
  };

  const playPrevious = () => {
    if (currentSongIndex > 0) {
      setCurrentSongIndex(currentSongIndex - 1);
      setCurrentSong(songs[currentSongIndex - 1]);
    } else if (repeatMode === "all") {
      setCurrentSongIndex(songs.length - 1);
      setCurrentSong(songs[songs.length - 1]);
    }
    setIsPlaying(true);
  };

  const toggleRepeat = () => {
    setRepeatMode((prevMode) => (prevMode === "off" ? "one" : "off"));
  };

  interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
  }

  const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-gray-800 p-6 rounded-lg relative">
          <button
            type="button"
            onMouseDown={onClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-white"
          >
            <X size={24} />
          </button>
          {children}
        </div>
      </div>
    );
  };

  interface ProfileProps {
    user: User | null;
    playlists: Playlist[];
    logout: () => Promise<void>;
    changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
    changeEmail: (newEmail: string) => Promise<void>;
    changeName: (newName: string) => Promise<void>;
    showOldPassword: boolean;
    setShowOldPassword: React.Dispatch<React.SetStateAction<boolean>>;
    showNewPassword: boolean;
    setShowNewPassword: React.Dispatch<React.SetStateAction<boolean>>;
    showConfirmPassword: boolean;
    setShowConfirmPassword: React.Dispatch<React.SetStateAction<boolean>>;
  }

  const Profile: React.FC<ProfileProps> = ({
    user,
    playlists,
    logout,
    changePassword,
    changeEmail,
    changeName,
    showOldPassword,
    setShowOldPassword,
    showNewPassword,
    setShowNewPassword,
    showConfirmPassword,
    setShowConfirmPassword
  }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newName, setNewName] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
    const [isEditingAvatar, setIsEditingAvatar] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [selectedSetting, setSelectedSetting] = useState<string | null>(null);
    const [userPlaylists, setUserPlaylists] = useState<Playlist[]>([]);
    const [likedSongs, setLikedSongs] = useState<Song[]>([]);

    const handleSettingClick = (setting: string) => {
      setSelectedSetting(setting);
      setIsSettingsOpen(false);
    };

    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();

    useEffect(() => {
      if (user && user.photoURL) {
        setAvatarUrl(user.photoURL);
      }
    }, [user]);

    useEffect(() => {
      const fetchUserData = async () => {
        if (user) {
          // Récupérer les playlists de l'utilisateur
          const playlistsQuery = query(collection(db, "playlists"), where("userId", "==", user.id));
          const playlistsSnapshot = await getDocs(playlistsQuery);
          const userPlaylistsData = playlistsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Playlist));
          setUserPlaylists(userPlaylistsData);

          // Récupérer les chansons likées par l'utilisateur
          const likedSongsQuery = query(collection(db, "songs"), where("likedBy", "array-contains", user.id));
          const likedSongsSnapshot = await getDocs(likedSongsQuery);
          const userLikedSongs = likedSongsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Song));
          setLikedSongs(userLikedSongs);
        }
      };

      fetchUserData();
    }, [user, db]);

    const validatePassword = (password: string): boolean => {
      // Au moins 8 caractères
      const minLength = password.length >= 8;

      // Au moins une majuscule
      const hasUpperCase = /[A-Z]/.test(password);

      // Au moins une minuscule
      const hasLowerCase = /[a-z]/.test(password);

      // Au moins un chiffre
      const hasNumber = /\d/.test(password);

      // Au moins un caractère spécial
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>\-]/.test(password);

      return minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
    };

    const validateEmail = (email: string): boolean => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };

    const handleChangePassword = async (e: React.FormEvent) => {
      e.preventDefault();
      setError(null);
      setSuccess(null);

      if (!validatePassword(newPassword)) {
        setError("Le nouveau mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.");
        return;
      }

      if (newPassword !== confirmPassword) {
        setError("Les mots de passe ne correspondent pas.");
        return;
      }

      try {
        const user = auth.currentUser;
        if (user && user.email) {
          const credential = EmailAuthProvider.credential(user.email, oldPassword);
          await reauthenticateWithCredential(user, credential);
          await updatePassword(user, newPassword);
          setSuccess('Mot de passe changé avec succès. Vous allez être déconnecté.');
          setTimeout(() => {
            logout();
          }, 3000);
        }
      } catch (err) {
        if (err instanceof Error) {
          switch (err.name) {
            case 'AuthError':
              setError('Ancien mot de passe incorrect.');
              break;
            case 'NetworkError':
              setError('Erreur de connexion. Veuillez réessayer.');
              break;
            default:
              setError('Une erreur est survenue. Veuillez réessayer.');
          }
        }
      }
    };

    const handleChangeEmail = async (e: React.FormEvent) => {
      e.preventDefault();
      setError(null);
      setSuccess(null);

      if (!validateEmail(newEmail)) {
        setError("Veuillez entrer une adresse email valide.");
        return;
      }

      try {
        console.log("Tentative de changement d'email vers:", newEmail);
        const user = auth.currentUser;
        if (user) {
          // Demander le mot de passe actuel pour la ré-authentification
          const password = prompt("Pour des raisons de sécurité, veuillez entrer votre mot de passe actuel:");
          if (!password) {
            setError("Mot de passe requis pour changer l'email.");
            return;
          }

          // Créer les identifiants
          const credential = EmailAuthProvider.credential(user.email!, password);

          // Ré-authentifier l'utilisateur
          await reauthenticateWithCredential(user, credential);

          // Maintenant, procéder au changement d'email
          await verifyBeforeUpdateEmail(user, newEmail);
          setSuccess('Un e-mail de vérification a été envoyé à votre nouvelle adresse. Veuillez vérifier votre boîte de réception et suivre les instructions pour confirmer le changement.');
        }
      } catch (err) {
        console.error("Erreur lors du changement d'email:", err);
        if (err instanceof Error) {
          if ('code' in err && err.code === "auth/wrong-password") {
            setError("Mot de passe incorrect. Veuillez réessayer.");
          } else {
            setError(err.message);
          }
        }
      }
    };

    const handleChangeName = async (e: React.FormEvent) => {
      e.preventDefault();
      setError(null);
      setSuccess(null);

      if (newName.length > 12) {
        setError("Le nom d'utilisateur ne peut pas dépasser 12 caractères.");
        return;
      }

      try {
        await changeName(newName);
        setSuccess('Nom d\'utilisateur mis à jour avec succès.');
        setNewName('');
      } catch (err) {
        setError('Une erreur est survenue. Veuillez réessayer.');
      }
    };

    const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxSize = 5 * 1024 * 1024; // 5MB

        if (!allowedTypes.includes(file.type)) {
          setError("Le type de fichier n'est pas autorisé. Veuillez choisir une image JPEG, PNG ou GIF.");
          return;
        }

        if (file.size > maxSize) {
          setError("L'image est trop volumineuse. La taille maximale autorisée est de 5MB.");
          return;
        }

        try {
          const user = auth.currentUser;
          if (user) {
            const avatarRef = ref(storage, `avatars/${user.uid}`);
            await uploadBytes(avatarRef, file);
            const downloadURL = await getDownloadURL(avatarRef);
            await updateProfile(user, { photoURL: downloadURL });
            setAvatarUrl(downloadURL);
            setSuccess('Avatar mis à jour avec succès.');
          }
        } catch (err) {
          setError('Une erreur est survenue lors de la mise à jour de l\'avatar. Veuillez réessayer.');
        }
      }
    };

    if (!user) return <div>Veuillez vous connecter pour voir votre profil.</div>;

    return (
      <div className="p-4">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <div className="relative mr-4">
              <img
                src={avatarUrl || "/default-avatar.png"}
                alt="Avatar"
                className="w-16 h-16 rounded-full object-cover cursor-pointer"
                onClick={() => setIsEditingAvatar(true)}
              />
              {isEditingAvatar && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
                  <label htmlFor="avatar-upload" className="cursor-pointer">
                    <Camera size={24} className="text-white" />
                  </label>
                  <input
                    id="avatar-upload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleAvatarChange}
                  />
                </div>
              )}
            </div>
            <h2 className="text-2xl font-bold">{user?.name || 'Utilisateur'}</h2>
          </div>
          <div className="flex items-center space-x-2">
            <div className="relative flex">
              <button onClick={() => setIsSettingsOpen(!isSettingsOpen)} className="text-gray-400 hover:text-white">
                <Settings size={24} />
              </button>
              {isSettingsOpen && (
                <div className="absolute right-0 mt-6 w-48 bg-gray-800 rounded-md shadow-lg z-10">
                  <button onClick={() => handleSettingClick('password')} className="block px-4 py-2 text-sm text-white hover:bg-gray-700 w-full text-left">
                    Changer le mot de passe
                  </button>
                  <button onClick={() => handleSettingClick('email')} className="block px-4 py-2 text-sm text-white hover:bg-gray-700 w-full text-left">
                    Changer l'email
                  </button>
                  <button onClick={() => handleSettingClick('name')} className="block px-4 py-2 text-sm text-white hover:bg-gray-700 w-full text-left">
                    Changer le nom d'utilisateur
                  </button>
                </div>
              )}
            </div>
            <button onClick={logout} className="text-red-500 hover:text-red-700">
              <LogOut size={24} />
            </button>
          </div>
        </div>

        <p className="mb-2">Email: {user?.email}</p>

        {selectedSetting === 'password' && (
          <Modal isOpen={true} onClose={() => setSelectedSetting(null)}>
            <h2 className="text-xl font-bold mb-4">Changer le mot de passe</h2>
            <form onSubmit={handleChangePassword}>
              <div className="relative mb-2">
                <input
                  type={showOldPassword ? "text" : "password"}
                  placeholder="Ancien mot de passe"
                  value={DOMPurify.sanitize(oldPassword)}
                  onChange={(e) => setOldPassword(DOMPurify.sanitize(e.target.value))}
                  className="w-full p-2 bg-gray-700 text-white rounded"
                  required
                />
                <button
                  type="button"
                  onMouseDown={() => setShowOldPassword(!showOldPassword)}
                  className="absolute right-2 top-2 text-gray-400"
                >
                  {showOldPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                </button>
              </div>
              <div className="relative mb-2">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Nouveau mot de passe"
                  value={DOMPurify.sanitize(newPassword)}
                  onChange={(e) => setNewPassword(DOMPurify.sanitize(e.target.value))}
                  className="w-full p-2 bg-gray-700 text-white rounded"
                  required
                />
                <button
                  type="button"
                  onMouseDown={() => setShowNewPassword(!showNewPassword)}
                  className="absolute right-2 top-2 text-gray-400"
                >
                  {showNewPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                </button>
              </div>
              <div className="relative mb-2">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirmer le nouveau mot de passe"
                  value={DOMPurify.sanitize(confirmPassword)}
                  onChange={(e) => setConfirmPassword(DOMPurify.sanitize(e.target.value))}
                  className="w-full p-2 bg-gray-700 text-white rounded"
                  required
                />
                <button
                  type="button"
                  onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-2 top-2 text-gray-400"
                >
                  {showConfirmPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                </button>

                {error && <p className="text-red-500 mb-4">{error}</p>}
                {success && <p className="text-green-500 mb-4">{success}</p>}

                <button type="submit" className="bg-blue-500 text-white px-4 py-2 mt-2 rounded">
                  Changer le mot de passe
                </button>
              </div>
            </form>
          </Modal>
        )
        }

        {
          selectedSetting === 'email' && (
            <Modal isOpen={true} onClose={() => setSelectedSetting(null)}>
              <h2 className="text-xl font-bold mb-4">Changer l'email</h2>
              <form onSubmit={handleChangeEmail}>
                <input
                  type="email"
                  placeholder="Nouvelle adresse email"
                  value={DOMPurify.sanitize(newEmail)}
                  onChange={(e) => setNewEmail(DOMPurify.sanitize(e.target.value))}
                  className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
                  required
                />

                {error && <p className="text-red-500 mb-4">{error}</p>}
                {success && <p className="text-green-500 mb-4">{success}</p>}

                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                  Changer l'email
                </button>
              </form>
            </Modal>
          )
        }

        {
          selectedSetting === 'name' && (
            <Modal isOpen={true} onClose={() => setSelectedSetting(null)}>
              <h2 className="text-xl font-bold mb-4">Changer le nom d'utilisateur</h2>
              <form onSubmit={handleChangeName}>
                <input
                  type="text"
                  placeholder="Nouveau nom d'utilisateur (max 12 caractères)"
                  value={DOMPurify.sanitize(newName)}
                  onChange={(e) => setNewName(DOMPurify.sanitize(e.target.value).slice(0, 12))}
                  className="w-full p-2 mb-2 bg-gray-700 text-white rounded"
                  required
                />

                {error && <p className="text-red-500 mb-4">{error}</p>}
                {success && <p className="text-green-500 mb-4">{success}</p>}

                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                  Changer le nom d'utilisateur
                </button>
              </form>
            </Modal>
          )
        }
      </div >
    );
  };

  const handlePlaylistNameChange = (value: string) => {
    setNewPlaylistName(value);
    console.log("New playlist name:", value);
  };

  const openCreatePlaylistModal = () => {
    setIsCreatePlaylistModalOpen(true);
    setNewPlaylistName("");
  };

  const CreatePlaylistModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onCreate: (name: string) => void;
  }> = ({ isOpen, onClose, onCreate }) => {
    const [name, setName] = useState("");

    const handleCreate = () => {
      if (name.trim()) {
        onCreate(name);
        setName("");
        onClose();
      }
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <h2 className="text-xl font-bold mb-4">Créer une nouvelle playlist</h2>
        <input
          type="text"
          value={DOMPurify.sanitize(name)}
          onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
          className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
          placeholder="Nom de la playlist"
        />
        <button
          type="button"
          onMouseDown={handleCreate}
          className="bg-green-500 text-white px-4 py-2 rounded w-full"
        >
          Créer
        </button>
      </Modal>
    );
  };

  const EditPlaylistModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onUpdate: (name: string) => void;
    initialName: string;
  }> = ({ isOpen, onClose, onUpdate, initialName }) => {
    const [name, setName] = useState(initialName);

    useEffect(() => {
      setName(initialName);
    }, [initialName]);

    const handleUpdate = () => {
      if (name.trim()) {
        onUpdate(name);
        onClose();
      }
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <h2 className="text-xl font-bold mb-4">Modifier la playlist</h2>
        <input
          type="text"
          value={DOMPurify.sanitize(name)}
          onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
          className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
          placeholder="Nouveau nom de la playlist"
        />
        <button
          type="button"
          onMouseDown={handleUpdate}
          className="bg-blue-500 text-white px-4 py-2 rounded w-full"
        >
          Mettre à jour
        </button>
      </Modal>
    );
  };

  const createPlaylist = async (name: string): Promise<Playlist> => {
    if (!user) {
      throw new Error("L'utilisateur doit être connecté pour créer une playlist.");
    }
    const sanitizedName = name.trim().slice(0, 14);
    const newPlaylist: Playlist = {
      name: sanitizedName,
      songs: [],
      imageUrl: '',
      userId: user.id ?? 'anonymous',  // Utilisation de l'opérateur de coalescence nulle
    };
    const docRef = await addDoc(collection(db, "playlists"), newPlaylist);
    const createdPlaylist = { ...newPlaylist, id: docRef.id };
    setPlaylists([...playlists, createdPlaylist]);
    return createdPlaylist;
  };

  const updatePlaylist = async (name: string) => {
    if (!currentEditPlaylist) return;
    const sanitizedName = name.trim().slice(0, 14);
    const playlistRef = doc(db, "playlists", currentEditPlaylist.id!);
    await updateDoc(playlistRef, { name: sanitizedName });
    setPlaylists(
      playlists.map((p) =>
        p.id === currentEditPlaylist.id ? { ...p, name: sanitizedName } : p,
      ),
    );
    setCurrentEditPlaylist(null);
  };

  const deletePlaylist = async (playlistId: string) => {
    await deleteDoc(doc(db, "playlists", playlistId));
    setPlaylists(playlists.filter((p) => p.id !== playlistId));
  };

  const addSongToPlaylist = async (playlistId: string, songId: string): Promise<void> => {
    const playlistRef = doc(db, "playlists", playlistId);
    const playlist = playlists.find((p) => p.id === playlistId);
    if (playlist) {
      const updatedSongs = [...playlist.songs, songId];
      await updateDoc(playlistRef, { songs: updatedSongs });
      setPlaylists(
        playlists.map((p) =>
          p.id === playlistId ? { ...p, songs: updatedSongs } : p
        )
      );
    }
  };

  const removeSongFromPlaylist = async (playlistId: string, songId: string) => {
    const playlistRef = doc(db, "playlists", playlistId);
    const playlist = playlists.find((p) => p.id === playlistId); // Utilisez playlistId ici
    if (playlist && playlist.songs) {
      const updatedSongs = playlist.songs.filter((id) => id !== songId);
      await updateDoc(playlistRef, { songs: updatedSongs });
      setPlaylists(
        playlists.map((p) =>
          p.id === playlistId ? { ...p, songs: updatedSongs } : p,
        ),
      );
    }
  };

  const AuthModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onSignUp: (name: string, email: string, password: string) => void;
    onLogin: (email: string, password: string) => void;
  }> = ({ isOpen, onClose, onSignUp, onLogin }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSignUp, setIsSignUp] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if (isSignUp) {
        if (password !== confirmPassword) {
          alert("Les mots de passe ne correspondent pas.");
          return;
        }
        onSignUp(name, email, password);
      } else {
        onLogin(email, password);
      }
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <form onSubmit={handleSubmit}>
          {isSignUp && (
            <input
              type="text"
              value={DOMPurify.sanitize(name)}
              onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
              placeholder="Nom d'utilisateur"
              required
            />
          )}
          <input
            type="email"
            value={DOMPurify.sanitize(email)}
            onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={DOMPurify.sanitize(password)}
            onChange={(e) => setPassword(DOMPurify.sanitize(e.target.value))}
            placeholder="Mot de passe"
            required
          />
          {isSignUp && (
            <input
              type="password"
              value={DOMPurify.sanitize(confirmPassword)}
              onChange={(e) => setConfirmPassword(DOMPurify.sanitize(e.target.value))}
              placeholder="Confirmer le mot de passe"
              required
            />
          )}
          <button type="submit">{isSignUp ? "S'inscrire" : "Se connecter"}</button>
        </form>
        <button onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp ? "Déjà un compte ? Se connecter" : "Créer un compte"}
        </button>
      </Modal>
    );
  };

  const openAuthModal = () => {
    setIsAuthModalOpen(true);
  };

  const isPasswordStrong = (password: string) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{ }|<>]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    return (
      minLength && hasUpperCase && hasLowerCase && hasSpecialChar && hasNumber
    );
  };

  const auth = getAuth(app);

  const signUp = async (name: string, email: string, password: string): Promise<string | null> => {
    try {
      if (!validateEmail(email)) {
        return "Veuillez entrer une adresse email valide.";
      }

      if (!validatePassword(password)) {
        return "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.";
      }

      if (name.length > 12) {
        return "Le nom d'utilisateur ne peut pas dépasser 12 caractères.";
      }

      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        return "Il semblerait que vous ayez déjà un compte avec cette adresse email.";
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName: name });
      await setDoc(doc(db, "users", user.uid), {
        name,
        email,
        role: 'user',
      });

      setUser({
        ...user,
        id: user.uid,
        name: user.displayName || "",
        email: user.email || "",
        role: "user",
      });

      return null;
    } catch (error) {
      console.error("Erreur lors de l'inscription", error);
      return "Erreur lors de l'inscription. Veuillez réessayer.";
    }
  };

  const login = async (email: string, password: string): Promise<string | null> => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userDoc = await getDoc(doc(db, "users", user.uid));
      const userData = userDoc.data();
      setUser({
        ...user,
        id: user.uid,
        name: userData?.name || user.displayName || "",
        email: user.email || "",
        role: userData?.role || 'user',
      });
      return null;
    } catch (error) {
      console.error("Erreur lors de la connexion", error);
      return "Email ou mot de passe incorrect.";
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("Erreur lors de la déconnexion", error);
    }
  };

  return (
    <Router>
      <div className="bg-black text-white min-h-screen">
        {!user ? (
          <AuthScreen
            onLogin={handleLogin}
            onSignUp={handleSignUp}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        ) : (
          <>
            <Routes>
              <Route path="/" element={
                <MobileLayout
                  currentSong={currentSong}
                  isPlaying={isPlaying}
                  togglePlay={togglePlay}
                  audioRef={audioRef}
                >
                  <Home
                    songs={songs}
                    setCurrentSong={handleSetCurrentSong}
                    toggleLikeSong={toggleLikeSong}
                    currentSong={currentSong}
                    isPlaying={isPlaying}
                    togglePlay={togglePlay}
                    playlists={playlists}
                    addSongToPlaylist={addSongToPlaylist}
                    user={user}
                    handlePlayClick={handlePlayClick}
                  />
                </MobileLayout>
              } />
              <Route path="/collection" element={
                <MobileLayout
                  currentSong={currentSong}
                  isPlaying={isPlaying}
                  togglePlay={togglePlay}
                  audioRef={audioRef}
                >
                  <Collection
                    user={user}
                    setCurrentSong={handleSetCurrentSong}
                    currentSong={currentSong}
                    isPlaying={isPlaying}
                    togglePlay={togglePlay}
                    toggleLikeSong={toggleLikeSong}
                    playlists={playlists}
                    instrumentals={songs}
                    onAddPlaylist={createPlaylist}
                    onAddInstrumental={addInstrumental}
                    addSongToPlaylist={addSongToPlaylist}
                  />
                </MobileLayout>
              } />
              <Route path="/playlist/:id" element={
                <MobileLayout
                  currentSong={currentSong}
                  isPlaying={isPlaying}
                  togglePlay={togglePlay}
                  audioRef={audioRef}
                >
                  <PlaylistView
                    user={user}
                    playlists={playlists}
                    songs={songs}
                    setCurrentSong={handleSetCurrentSong}
                    addSongToPlaylist={addSongToPlaylist}
                    removeSongFromPlaylist={removeSongFromPlaylist}
                    openEditPlaylistModal={openEditPlaylistModal}
                    deletePlaylist={deletePlaylist}
                    toggleLikeSong={toggleLikeSong}
                    updatePlaylistImage={updatePlaylistImage}
                    updatePlaylistName={updatePlaylistName}
                  />
                </MobileLayout>
              } />
              <Route path="/liked" element={
                <MobileLayout
                  currentSong={currentSong}
                  isPlaying={isPlaying}
                  togglePlay={togglePlay}
                  audioRef={audioRef}
                >
                  <LikedSongs
                    user={user}
                    songs={songs}
                    setCurrentSong={handleSetCurrentSong}
                    toggleLikeSong={toggleLikeSong}
                  />
                </MobileLayout>
              } />
              <Route
                path="/profile"
                element={
                  <MobileLayout
                    currentSong={currentSong}
                    isPlaying={isPlaying}
                    togglePlay={togglePlay}
                    audioRef={audioRef}
                  >
                    <Profile
                      user={user}
                      playlists={playlists}
                      logout={logout}
                      changePassword={changePassword}
                      changeEmail={changeEmail}
                      changeName={changeName}
                      showOldPassword={showOldPassword}
                      setShowOldPassword={setShowOldPassword}
                      showNewPassword={showNewPassword}
                      setShowNewPassword={setShowNewPassword}
                      showConfirmPassword={showConfirmPassword}
                      setShowConfirmPassword={setShowConfirmPassword}
                    />
                  </MobileLayout>
                }
              />
            </Routes>
            <CreatePlaylistModal
              isOpen={isCreatePlaylistModalOpen}
              onClose={() => setIsCreatePlaylistModalOpen(false)}
              onCreate={createPlaylist}
            />
            <EditPlaylistModal
              isOpen={isEditPlaylistModalOpen}
              onClose={() => setIsEditPlaylistModalOpen(false)}
              onUpdate={updatePlaylist}
              initialName={currentEditPlaylist?.name || ""}
            />
            <AuthModal
              isOpen={isAuthModalOpen}
              onClose={() => setIsAuthModalOpen(false)}
              onSignUp={signUp}
              onLogin={login}
            />
          </>
        )}
      </div>
    </Router>
  );
};

interface HeaderProps {
  user: User | null;
  openAuthModal: () => void;
  logout: () => void;
}

const Header: React.FC<HeaderProps> = ({ user, openAuthModal, logout }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const history = useNavigate();
  const searchRef = useRef<HTMLDivElement>(null);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      history(`/search?q=${encodeURIComponent(searchTerm)}`);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsSearchActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="flex flex-col md:flex-row justify-between items-center mb-4 md:mb-8">
      <div ref={searchRef} className={`relative w-full md:w-64 mb-4 md:mb-0 ${isSearchActive ? "w-full" : "w-full md:w-40"} transition-all duration-300 ease-in-out`}>
        <form onSubmit={handleSearch} className="flex items-center">
          <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            placeholder={isSearchActive ? "Rechercher des titres, artistes..." : "Rechercher"}
            value={DOMPurify.sanitize(searchTerm)}
            onChange={(e) => setSearchTerm(DOMPurify.sanitize(e.target.value))}
            className="w-full pl-10 pr-4 py-2 bg-gray-800 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </form>
      </div>
      {user ? (
        <div className="flex items-center space-x-4">
          <Link
            to="/profile"
            className="text-white hover:text-gray-300 text-sm md:text-base"
          >
            {user.name || user.email}
          </Link>
          <button
            type="button"
            onMouseDown={logout}
            className="bg-white text-black px-3 py-1 md:px-4 md:py-2 rounded-full text-sm md:text-base"
          >
            Déconnexion
          </button>
        </div>
      ) : (
        <button
          type="button"
          onMouseDown={openAuthModal}
          className="bg-white text-black px-3 py-1 md:px-4 md:py-2 rounded-full text-sm md:text-base"
        >
          Connexion / Inscription
        </button>
      )}
    </header>
  );
};

interface HomeProps {
  songs: Song[];
  setCurrentSong: (song: Song) => void;
  toggleLikeSong: (songId: string) => void;
  currentSong: Song | null;
  isPlaying: boolean;
  togglePlay: () => void;
  playlists: Playlist[];
  addSongToPlaylist: (playlistId: string, songId: string) => Promise<void>;
  handlePlayClick: (e: React.MouseEvent<HTMLButtonElement>, song: Song) => Promise<void>;
  user: User | null;
}

const Home: React.FC<HomeProps> = ({
  songs,
  setCurrentSong,
  toggleLikeSong,
  currentSong,
  isPlaying,
  togglePlay,
  playlists,
  addSongToPlaylist,
  handlePlayClick,
  user,
}) => {
  const safeSongs = songs || [];
  const featuredSongs = safeSongs.slice(0, 6);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isAddToPlaylistModalOpen, setIsAddToPlaylistModalOpen] = useState(false);
  const [selectedSong, setSelectedSong] = useState<Song | null>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => { };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleQRCodeClick = () => {
    if (isMobile) {
      if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            console.log('Appareil photo ouvert');
            stream.getTracks().forEach(track => track.stop());
          })
          .catch((err) => {
            console.error("Erreur lors de l'ouverture de l'appareil photo:", err);
          });
      } else {
        console.log("L'accès à l'appareil photo n'est pas supporté sur ce navigateur");
      }
    }
  };

  const handleAddToPlaylist = (song: Song) => {
    setSelectedSong(song);
    setIsAddToPlaylistModalOpen(true);
  };

  const handlePlaylistSelect = (playlistId: string) => {
    if (selectedSong && selectedSong.id) {
      addSongToPlaylist(playlistId, selectedSong.id);
      setIsAddToPlaylistModalOpen(false);
    }
  };

  const userPlaylists = playlists.filter(playlist => playlist.userId === user?.id);

  return (
    <div className="flex flex-col min-h-screen bg-black text-white">
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 p-4 text-center">
        <p className="text-white font-bold"><a href="https://rozo-atelier.com">Nouvelle collection disponible dès maintenant !</a></p>
      </div>

      <header className="flex justify-center items-center p-4">
        <img src="logo.png" alt="Logo" className="w-20 h-20 mb-4" />
      </header>

      <main className="flex-grow overflow-y-auto px-4 pb-32">
        <h1 className="text-2xl font-bold mb-6">Bienvenue, {user?.name || 'Utilisateur'}</h1>

        <div
          className="flex items-center bg-gray-800 rounded-lg p-4 mb-6 cursor-pointer"
          onMouseDown={handleQRCodeClick}
        >
          <img src="/frame.png" alt="QR Code" className="w-24 h-24 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">Ajoutez une instrumentale</h2>
            <p className="text-sm text-gray-300">
              Pour obtenir une instrumental, veuillez scanner le QR code sur le vêtement via votre appareil photo
              {isMobile && " (Cliquez pour ouvrir l'appareil photo)"}
            </p>
          </div>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-bold mb-4">Mes Instrumentales</h2>
          <div
            ref={scrollRef}
            className="flex space-x-4 overflow-x-auto scrollbar-hide"
            style={{ scrollBehavior: 'smooth' }}
          >
            {featuredSongs.map((song) => (
              <div key={song.id} className="flex-shrink-0 w-40">
                <div className="relative">
                  <img
                    src={song.thumbnailUrl || "/api/placeholder/160/160"}
                    alt={song.name}
                    className={`w-full h-40 object-cover rounded-lg album-cover ${currentSong?.id === song.id && isPlaying ? 'spinning' : ''}`}
                  />
                  <button
                    type="button"
                    onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => handlePlayClick(e, song)}
                    className="absolute bottom-2 right-2 bg-green-500 text-white rounded-full p-2 hover:bg-green-600"
                  >
                    {currentSong?.id === song.id && isPlaying ? (
                      <FaPause size={15} />
                    ) : (
                      <FaPlay size={15} />
                    )}
                  </button>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <div className="flex space-x-2">
                    <button
                      type="button"
                      onMouseDown={() => handleAddToPlaylist(song)}
                      className="text-white hover:text-green-500"
                    >
                      <Plus size={20} />
                    </button>
                    <button
                      type="button"
                      onMouseDown={() => song.id ? toggleLikeSong(song.id) : undefined}
                      className={`text-${user?.likedSongs?.includes(song.id ?? '') ? 'red' : 'white'}-500 hover:text-red-500`}
                    >
                      <Heart size={20} fill={song.id && user?.likedSongs?.includes(song.id!) ? "currentColor" : "none"} />
                    </button>
                  </div>
                </div>
                <p className="mt-2 font-semibold text-sm truncate">{song.name}</p>
                <p className="text-xs text-gray-400 truncate">{song.artist}</p>
              </div>
            ))}
          </div>
        </div>

      </main>

      {isAddToPlaylistModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-80">
            <h3 className="text-xl font-bold mb-4">Ajouter à une playlist</h3>
            <button
              type="button"
              onMouseDown={() => {/* Logique pour créer une nouvelle playlist */ }}
              className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded mb-2"
            >
              Créer une nouvelle playlist
            </button>
            {userPlaylists.map((playlist) => (
              <button
                type="button"
                key={playlist.id}
                onMouseDown={() => handlePlaylistSelect(playlist.id!)}
                className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded"
              >
                {playlist.name}
              </button>
            ))}
            <button
              type="button"
              onMouseDown={() => setIsAddToPlaylistModalOpen(false)}
              className="mt-4 w-full bg-red-500 text-white py-2 px-4 rounded"
            >
              Annuler
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const LikedSongs: React.FC<{
  songs: Song[];
  setCurrentSong: (song: Song) => Promise<void>;
  toggleLikeSong: (songId: string) => void;
  user: User | null;
}> = ({ songs, setCurrentSong, toggleLikeSong, user }) => {
  const likedSongs = songs.filter((song) => song.id && user?.likedSongs?.includes(song.id));

  const handlePlay = async (song: Song) => {
    await setCurrentSong(song);
  };

  return (
    <section>
      <h2 className="text-2xl font-bold mb-4">Titres likés</h2>
      <div className="space-y-2">
        {likedSongs.map((song) => (
          <div
            key={song.id}
            className="bg-gray-800 p-4 rounded flex justify-between items-center"
          >
            <div>
              <h3 className="font-semibold">{song.name}</h3>
              <p className="text-sm text-gray-400">{song.artist}</p>
            </div>
            <div className="flex items-center space-x-2">
              <button
                type="button"
                onMouseDown={() => song.id ? toggleLikeSong(song.id) : undefined}
                className="text-red-500 hover:text-red-700"
              >
                <Heart className="w-5 h-5" fill="currentColor" />
              </button>
              <button
                type="button"
                onMouseDown={() => handlePlay(song)}
                className="bg-green-500 text-white px-3 py-1 rounded-full"
              >
                Jouer
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

interface SearchProps {
  songs: Song[];
  setCurrentSong: (song: Song) => void;
  toggleLikeSong: (songId: string) => void;
  user: User | null;  // Ajoutez cette ligne
}

const Search: React.FC<SearchProps> = ({
  songs,
  setCurrentSong,
  toggleLikeSong,
  user,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get("q") || "";

  const handlePlay = async (song: Song) => {
    await setCurrentSong(song);
  };

  // Vérifiez si songs est undefined ou null, si c'est le cas, utilisez un tableau vide
  const safeSongs = songs || [];

  const filteredSongs = safeSongs.filter(
    (song) =>
      song.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      song.artist.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <section>
      <h2 className="text-2xl font-bold mb-4">
        Résultats de recherche pour "{searchTerm}"
      </h2>
      <div className="space-y-2">
        {filteredSongs.map((song) => (
          <div
            key={song.id}
            className="flex items-center justify-between bg-gray-800 p-2 rounded"
          >
            <div>
              <h3 className="font-semibold">{song.name}</h3>
              <p className="text-sm text-gray-400">{song.artist}</p>
            </div>
            <div className="flex items-center space-x-2">
              <button
                type="button"
                onMouseDown={() => song.id ? toggleLikeSong(song.id) : undefined}
                className={`text-${user?.likedSongs?.includes(song.id!) ? 'red' : 'white'}-500 hover:text-red-500`}
              >
                <Heart
                  className="w-5 h-5"
                  fill={song.isLiked ? "currentColor" : "none"}
                />
              </button>
              <button
                type="button"
                onMouseDown={() => handlePlay(song)}
                className="bg-green-500 text-white px-3 py-1 rounded-full"
              >
                Jouer
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

interface PlaylistViewProps {
  playlists: Playlist[];
  songs: Song[];
  setCurrentSong: (song: Song) => void;
  addSongToPlaylist: (playlistId: string, songId: string) => void;
  removeSongFromPlaylist: (playlistId: string, songId: string) => void;
  openEditPlaylistModal: (playlist: Playlist) => void;
  deletePlaylist: (playlistId: string) => void;
  toggleLikeSong: (songId: string) => void;
  updatePlaylistImage: (playlistId: string, newImageUrl: string) => Promise<void>;
  updatePlaylistName: (playlistId: string, newName: string) => Promise<void>;
  user: User | null;
}

const PlaylistView: React.FC<PlaylistViewProps> = ({
  playlists,
  songs,
  setCurrentSong,
  addSongToPlaylist,
  removeSongFromPlaylist,
  openEditPlaylistModal,
  deletePlaylist,
  toggleLikeSong,
  updatePlaylistImage,
  updatePlaylistName,
  user,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isEditImageModalOpen, setIsEditImageModalOpen] = useState(false);
  const [editedImage, setEditedImage] = useState<File | null>(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState("");
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const playlist = playlists.find((p) => p.id === id);
  const [imageUpdateStatus, setImageUpdateStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const modalRef = useRef<HTMLDivElement>(null);

  const handlePlay = async (song: Song) => {
    await setCurrentSong(song);
  };

  useEffect(() => {
    if (playlist) {
      setEditedName(playlist.name);
    }
  }, [playlist]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsEditImageModalOpen(false);
      }
    };

    if (isEditImageModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditImageModalOpen]);

  if (!playlist || !Array.isArray(playlist.songs)) {
    return <div>Chargement de la playlist...</div>;
  }

  const playlistSongs = songs.filter((song) => playlist.songs.includes(song.id || ""));

  const handleEditImage = async () => {
    if (editedImage && playlist && playlist.id) {
      try {
        setImageUpdateStatus('loading');
        const storage = getStorage();
        const imageRef = ref(storage, `playlist-images/${editedImage.name}`);
        await uploadBytes(imageRef, editedImage);
        const imageUrl = await getDownloadURL(imageRef);

        await updatePlaylistImage(playlist.id, imageUrl);

        setImageUpdateStatus('success');
        setTimeout(() => {
          setImageUpdateStatus('idle');
          setIsEditImageModalOpen(false);
        }, 3000);
      } catch (error) {
        console.error("Erreur lors de la mise à jour de l'image:", error);
        setImageUpdateStatus('error');
        setTimeout(() => setImageUpdateStatus('idle'), 3000);
      }
    }
  };

  const handleEditName = async () => {
    const sanitizedName = editedName.trim().replace(/[^a-zA-Z0-9]/g, '').slice(0, 25);
    if (sanitizedName && playlist && playlist.id) {
      try {
        await updatePlaylistName(playlist.id, sanitizedName);
        setIsEditingName(false);
      } catch (error) {
        console.error("Erreur lors de la mise à jour du nom:", error);
      }
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setEditedImage(e.target.files[0]);
    }
  };

  return (
    <section className="p-4">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <button type="button" onMouseDown={() => navigate('/collection')} className="mr-4">
            <ChevronLeft size={24} />
          </button>
          {isEditingName ? (
            <div className="flex items-center">
              <input
                type="text"
                value={DOMPurify.sanitize(editedName)}
                onChange={(e) => setEditedName(DOMPurify.sanitize(e.target.value).slice(0, 14))}
                className="bg-gray-700 text-white px-2 py-1 rounded mr-2"
              />
              <button type="button" onMouseDown={handleEditName} className="text-green-500">
                <Check size={20} />
              </button>
            </div>
          ) : (
            <div className="flex items-center">
              <h2 className="text-2xl font-bold mr-2 truncate max-w-[200px]">{playlist.name}</h2>
              <button type="button" onMouseDown={() => setIsEditingName(true)} className="text-gray-400 hover:text-white">
                <Edit size={20} />
              </button>
            </div>
          )}
        </div>
        <div className="relative">
          <button type="button" onMouseDown={() => setIsOptionsOpen(!isOptionsOpen)}>
            <MoreVertical size={24} />
          </button>
          {isOptionsOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-10">
              <button
                type="button"
                onClick={() => {
                  setIsEditImageModalOpen(true);
                  setIsOptionsOpen(false);
                }}
                className="block px-4 py-2 text-sm text-white hover:bg-gray-700 w-full text-left"
              >
                Modifier l'image
              </button>
              <button
                type="button"
                onMouseDown={() => {
                  if (window.confirm("Êtes-vous sûr de vouloir supprimer cette playlist ?")) {
                    deletePlaylist(id!);
                    navigate('/collection');
                  }
                }}
                className="block px-4 py-2 text-sm text-white hover:bg-gray-700 w-full text-left"
              >
                Supprimer la playlist
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Liste des chansons */}
      <div className="space-y-2">
        {playlistSongs.map((song, index) => (
          <div
            key={song.id}
            className="flex items-center justify-between py-2 border-b border-gray-700"
          >
            <div className="flex items-center">
              <span className="w-6 text-gray-400">{index + 1}</span>
              <img
                src={song.thumbnailUrl || "/api/placeholder/40/40"}
                alt={song.name}
                className="w-10 h-10 object-cover rounded mr-3"
              />
              <div>
                <h3 className="font-semibold">{song.name}</h3>
                <p className="text-sm text-gray-400">{song.artist}</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <button
                type="button"
                onMouseDown={() => song.id ? toggleLikeSong(song.id) : undefined}
                className={`text-${user?.likedSongs?.includes(song.id!) ? 'red' : 'white'}-500 hover:text-red-500`}
              >
                <Heart
                  size={20}
                  fill={song.isLiked ? "currentColor" : "none"}
                />
              </button>
              <button
                type="button"
                onMouseDown={() => handlePlay(song)}
                className="text-white"
              >
                <Play size={20} />
              </button>
              <button
                type="button"
                onMouseDown={() => playlist.id && song.id && removeSongFromPlaylist(playlist.id, song.id)}
                className="text-gray-400 hover:text-red-500"
              >
                <Trash2 size={20} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* Modal pour modifier l'image */}
      {isEditImageModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={modalRef} className="bg-gray-800 p-6 rounded-lg relative w-96">
            <button
              type="button"
              onMouseDown={() => setIsEditImageModalOpen(false)}
              className="absolute top-2 right-2 text-gray-400 hover:text-white"
            >
              <X size={24} />
            </button>
            <h2 className="text-xl font-bold mb-4">Modifier l'image de la playlist</h2>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="mb-4 w-full text-sm text-gray-400 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gray-700 file:text-white hover:file:bg-gray-600"
            />
            <button
              type="button"
              onMouseDown={handleEditImage}
              className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
              disabled={imageUpdateStatus === 'loading' || !editedImage}
            >
              {imageUpdateStatus === 'loading' ? 'Mise à jour en cours...' : 'Mettre à jour l\'image'}
            </button>
            {imageUpdateStatus === 'success' && (
              <p className="text-green-500 mt-2">Image mise à jour avec succès!</p>
            )}
            {imageUpdateStatus === 'error' && (
              <p className="text-red-500 mt-2">Erreur lors de la mise à jour de l'image.</p>
            )}
          </div>
        </div>
      )}
    </section>
  );
};
export default App;